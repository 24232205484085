.time-col {
  font-size: 15px;
  padding-left: 10px;
  font-weight: bold;
}

.details {
  font-size: 10px;
  font-style: italic;
}

.row-padding {
  padding: 10px;
}

.modal-full {
  min-width: 100%;
  margin: 0;
}

.modal-full .modal-content {
  min-height: 100vh;
}

#windy {
  width: 100%;
  height: 700px;
}